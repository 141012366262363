import webApi from "./api"

export class PaymentService {
	async getPinchCredentials(params) {
		return webApi.get('payment/get-pinch-credentials', { params })
	}
	
	async createPayer(data) {
		return webApi.post('payment/create-payer', data)
	}

	async logPayment(data) {
		return webApi.post('payment/log-payment', data)
	}

	async getParamsValue(params) {
		return webApi.get('payment/get-params-value', { params })
	}
}

const paymentService = new PaymentService()

export default paymentService